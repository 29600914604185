<template>

  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="brand-logo">
                  <img src="../../../assets/images/logo.svg">
                </div>
                <h4>Hello! let's get started</h4>
                <h6 class="font-weight-light">Sign in to continue.</h6>
                <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="onSubmit" class="pt-3">
                  <ValidationProvider name="email" rules="required|email">
                    <div slot-scope="{ errors }" class="form-group">
                      <input type="email" v-model="email" class="form-control form-control-lg" id="email" placeholder="Email">
                      <p>{{ errors[0] }}</p>
                      <p>{{ errors[1] }}</p>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="password" rules="min:6">
                    <div slot-scope="{ errors }" class="form-group">
                      <input type="password" v-model="password" class="form-control form-control-lg" id="email" placeholder="Password">
                      <p>{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                  <div class="mt-3">
                    <b-button type="submit" :disabled="invalid" variant="primary" class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn">{{ sign_in }}</b-button>
                  </div>
                  <div class="my-2 mt-5 d-flex justify-content-between align-items-center">
                    <div class="form-check m-0">
                      <label class="form-check-label text-muted small-text">
                        <input type="checkbox" v-model="checkbox_remember_me" class="form-check-input">
                        Keep me signed in
                        <i class="input-helper"></i>
                      </label>
                    </div>
                    <router-link to="/forgot-password" class="small-text text-muted  mb-0"> forgot password</router-link>
                  </div>
                </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
    <!-- <vue-snotify></vue-snotify> -->
  </section>

</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      checkbox_remember_me: false,
      sign_in: "SIGN IN"
    }
  },
  components: {
    ValidationObserver
  },
  methods: {
    checkLogin() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$snotify.warning('User already logged in', {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true

        })
        return false
      }
      return true
    },
    onSubmit() {
      this.sign_in = "PROCESSING..."
      if (!this.checkLogin()) return
      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password
        }
     }
     this.$store.dispatch('auth/loginJWT', payload)
     .then(() => {
        this.$snotify.success("Signed in successfully!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
       })
      })
      .catch(error => {
        this.sign_in = "SIGN IN"
        this.$snotify.warning(error, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true

        })
      })
    },
  }
}
</script>
<style scoped lang="scss">
.simple-snotify {
  display: flex;
  justify-content: center;
}
.small-text{
  font-size: 12px;
}
</style>
